@import "./m_variable.scss";

.input-color-active {
            background: white;
            color: black !important;
            border: 1px solid #00306747;
}

.input-color-disabled {
            background: #f7f7f7 !important;
            color: black !important;
            border: 1px solid #dbdbdb  !important;
}

/* normal input formcontol */
.m-form {
            label {
                color: #616161 !important;
            }

            input {
                        @extend .input-color-active;
                        border: 1px solid #00306747 !important;
                        height: 50px !important;
                        border-radius: 11px;
                        &.form-control {
                            &.ng-invalid {
                                padding: 0.375rem 0.75rem !important;
                            }
                        }
            }

            textarea {
                        @extend .input-color-active;
                        border-radius: 11px;
            }

            .dropdown, .dropup, .app-select {
                        .dropdown-toggle {
                                    @extend .input-color-active;
                                    height: 50px;
                                    border-radius: 11px;

                                    p, label {
                                                font-size: unset;
                                                font-weight: unset;
                                                color: black;
                                    }
                                    &.no-border {
                                        border: none !important;
                                        outline: none !important;
                                        box-shadow: none !important;
                                        text-align: center !important;
                                        padding-right: 40px !important;
                                    }
                        }

                        button {
                            &::after {
                                top: 24px;
                                right: 12px;
                            }
                            &[disabled] {
                                @extend .input-color-disabled;
                                opacity: 1;
                                &::after {
                                    opacity: 0.5;
                                }
                            }
                        }

                        .dropdown-menu {
                                    background: white;
                                    border: unset !important;
                                    box-shadow: 0px 0px 5px #00000030;
                                    top: 10px !important;
                                    border-radius: 8px;

                                    p, label {
                                                font-weight: unset;
                                                color: black !important;
                                                padding: 0.5rem 1.5rem !important;
                                                margin-left: 0 !important;
                                    }

                                    input {
                                                border: none !important;
                                                height: 40px !important;
                                                border-radius: 8px;
                                    }
                        }
            }

            .input-group-prepend {
                        width: 100% !important;

                        .input-group-text {
                                    height: 50px;
                                    background: white;
                                    border: 1px solid #b8c5d5;
                                    border-right: none;
                                    border-top-left-radius: 8px;
                                    border-bottom-left-radius: 8px;
                        }

                        .dropdown, .dropup, .app-select {
                                    width: 100% !important;

                                    .dropdown-toggle {
                                                border-top-left-radius: unset !important;
                                                border-bottom-left-radius: unset !important;
                                    }
                        }

                        input[type="checkbox"] {
                                    margin-top: 2px;
                                    margin-left: 4px;
                                    width: 16px;
                        }
            }

            /* styling date input */
            /*----------------*/
            .input-group {
                        input {
                                    height: 45px !important;
                                    margin-top: 5px;
                                    border-radius: 11px;
                                    border: unset !important;
                        }
            }

            mat-datepicker-toggle {
                        margin-top: 9px !important;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-appearance-outline .mat-form-field-outline-start {
                        border-radius: 10px 0 0 10px !important;
                        width: 10px !important;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-appearance-outline .mat-form-field-outline-end {
                        border-radius: 0 10px 10px 0 !important;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-appearance-outline .mat-form-field-outline {
                        background: white !important;
                        color: #00306747; // border color of date input
                        height: 50px !important;
                        border-radius: 13px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
                        background: #f7f7f7 !important;
                        color: #dbdbdb;
                        // border: 1px solid #dbdbdb !important;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-flex {
                        align-items: unset !important;
            }
}

/* input form control rounded */
.m-form-round {
            input {
                        border: none !important;
                        border-radius: 24px;
                        padding-left: 20px;
                        padding-right: 50px;
                        position: relative;
            }

            mat-icon {
                        position: absolute;
                        right: 24px;
                        top: 10px;
                        color: #adadad;
                        user-select: none;
            }

            .dropdown, .app-select {
                        .dropdown-toggle {
                                    border: none;
                                    border-radius: 24px;
                                    padding-left: 20px;

                                    p {
                                                font-size: unset;
                                                font-weight: unset;
                                    }

                                    &::after {
                                                content: none;
                                    }

                                    .default {
                                                color: #adadad;
                                    }
                        }

                        mat-icon {
                                    position: absolute;
                                    right: 0;
                                    top: 10px;
                                    color: #adadad;
                                    user-select: none;
                        }

                        .dropdown-menu {
                                    p {
                                                font-weight: unset;
                                    }
                        }
            }

            ::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #adadad !important;
                        opacity: 1 !important; /* Firefox */
            }

            :-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: #adadad !important;
            }

            ::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: #adadad !important;
            }
}

/* increase space size in between form control */
.form-row > .col,
.form-row > [class*="col-"] {
            padding-left: 14px !important;
            padding-right: 14px !important;
}

/* when form input is disabled */
.form-control:disabled,
.form-control[readonly] {
            @extend .input-color-disabled;
            opacity: 1 !important;
}

/* when date is disabled */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix:after,
.mat-input-element:disabled {
            color: black !important;
            background: #f7f7f7 !important;
}
