$main-color: #2f3292;
$main-background-color: #2f3292;

$main-button-bg-color: #2f3292;
$main-button-disable-bg-color: #a2a2a2;
$button-background-color: #2f3292;

$active-menu-color: #e4eeff;

$form-control-color: #194c5d !default;
$active-tab-color: #212cc7;

$gray_label: #686868;

$default-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
$card-title-color: white !default;
$card-title-background-color: #298043 !default;
$active-tab-color: #194c5d;

$form-control-color: #194c5d !default;
$form-label-color: #919191 !default;

$main-text-color: #194c5d;
$value: #194c5d;
$cancel: #e41f1f;
$list-color: #194c5d;
$dialog-header: #2A5298;

$pannel-pop-border-color: #2bbfa8 !default;
$pannel-content-color: #194c5d;

$zero: #27b300;
$one: #00c890;
$two: #e6bd20;
$three: #fb9d58;
$four: #ff1c1c;

/**
 * Login Variables
 */
$form-box-width: 400px;
$form-box-height: 380px;
$box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$box-width: 900px;
$box-height: 500px;
$box-radius: 10px;
$light-color: #FFF;

/**
 * Status Colors
 */
$create: #23c6eb;
$success: #00ae00;
$rejected: #ff6969;
$new-request: #8f97a6;
$warning: #d29803;
$waiting: #b0b0b0;
$assign: #ee891d;
$highlight: #0cb931;
$submit: #00b08a;
$verify: #00d6cb;
$reviewing: #5d8cb4;
$return: #ff9a31;
$suspended: #d76234;
$closed: #000000;
$re_open: #21786f;
$upload: #DE9D24;

$sidenav-content-bg: #F3F6F9;

/**
 * Screen Sizes
 */
$phone: 414px;
$ipad: 768px;
$medium-pc: 1300px;
$large-screen: 1580px;
